import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FetchApi } from "../../API/FetchApi";
import ToastMessage from "../../Utils/ToastMessage";
import { Endpoints } from "../../API/Endpoints";
import moment from "moment";
import Swal from "sweetalert2";
import LocationAutocomplete from "../../Components/LocationAutocomplete"
import { connect } from 'react-redux';
import CreateAvailabilityModal from "../../Components/createAvailability";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import CustomToolbar from "../../Components/CustomToolBar";
import dayjs from "dayjs";

const $ = window.jQuery;


function ManageAvailability(props) {
  const localizer = momentLocalizer(moment);
  const history = useNavigate();
  const [availabilityList, setAvailabilityList] = useState([]);
  const [selectedIndex, setSelectedLocation] = useState(null)
  const [rink, setRink] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userAvailablity, setUserAvailability] = useState([]);
  const [currentView, setCurrentView] = useState('month');
  const [eventDetails, setEventDetails] = useState({
    is_marked_availability: 0,
    id: null,
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: ''
  });

  const events = [
    {
        title: "Meeting",
        start: new Date("202406-02T09:30:00.0000000"),
        end: new Date("2023-06-02T10:00:00.0000000")
    },
    {
        title: "Lunch",
        start: new Date("2024-06-03T09:00:00.0000000"),
        end: new Date("2024-06-03T09:30:00.0000000")
    },
    {
        title: "Appointment",
        start: new Date("2024-07-01T09:30:00.0000000"),
        end: new Date("2024-07-01T10:00:00.0000000")
    }
  ];

  const [availabilityData, setAvailabilityData] = useState([
    { dayTitle: 'Sunday', day: "sunday", is_day_closed: 0, times: [{ start: null, end: null }] },
    { dayTitle: 'Monday', day: "monday", times: [{ start: null, end: null }], is_day_closed: 0 },
    { dayTitle: 'Tuesday', day: "tuesday", times: [{ start: null, end: null }], is_day_closed: 0 },
    { dayTitle: 'Wednesday', day: "wednesday", times: [{ start: null, end: null }], is_day_closed: 0 },
    { dayTitle: 'Thursday', day: "thursday", times: [{ start: null, end: null }], is_day_closed: 0 },
    { dayTitle: 'Friday', day: "friday", times: [{ start: null, end: null }], is_day_closed: 0 },
    { dayTitle: 'Saturday', day: "saturday", times: [{ start: null, end: null }], is_day_closed: 0 },
  ]);
  const availableChange = (e, dIndex, i, availabilityList) => {
    let availabilityDataInState = [...availabilityList]
    const is_day_closed = e.target.checked ? 1 : 0
    availabilityDataInState[i].availability_timing[dIndex].is_day_closed = is_day_closed;
    availabilityDataInState[i].availability_timing[dIndex].times = is_day_closed === 1 ? [{ start: null, end: null }] : [];
    setAvailabilityList(availabilityDataInState);
  };
  const timeChange = (e, dIndex, i, availabilityList, timeIndex, attribute) => {
    let availabilityDataInState = [...availabilityList]
    if(attribute === "start"){
    if(availabilityDataInState[i].availability_timing[dIndex].times.filter((item)=> item?.start == e.target.value).length > 0){
      Swal.fire({
        text: "This Time Already added",
        icon: "error",
      });
    }else{
      availabilityDataInState[i].availability_timing[dIndex].times[timeIndex][attribute] = e.target.value;
      setAvailabilityList(availabilityDataInState);
    }
  }else{
    if(availabilityDataInState[i].availability_timing[dIndex].times.filter((item)=> item?.end == e.target.value).length > 0){
      Swal.fire({
        text: "This Time Already added",
        icon: "error",
      });
    }else{
      availabilityDataInState[i].availability_timing[dIndex].times[timeIndex][attribute] = e.target.value;
      setAvailabilityList(availabilityDataInState);
    }
  }
  
  };
  const addTimes = (dIndex, i, availabilityList) => {
    let availabilityDataInState = [...availabilityList]
    availabilityDataInState[i].availability_timing[dIndex].times.push({
      start: '',
      end: ''
    });
    setAvailabilityList(availabilityDataInState);
  };
  const removeTimes = (dIndex, i, availabilityList, timeIndex) => {
    let availabilityDataInState = [...availabilityList]
    let times = availabilityDataInState[i].availability_timing[dIndex].times
    times.splice(timeIndex, 1)
    availabilityDataInState[i].availability_timing[dIndex].times = times
    setAvailabilityList(availabilityDataInState);
  };
  const dateValueChange = (e, days, i, availabilityList, dateIndex) => {
    let blockedDates = [...availabilityList[i].availability_block_dates]
    blockedDates[dateIndex][days] = e.target.value;
    let availabilityStateList = [...availabilityList]
    availabilityStateList[i].availability_block_dates = blockedDates;
    setAvailabilityList(availabilityStateList);
  };
  const addBlockedDates = (i, availabilityList) => {
    let blockedDates = [...availabilityList[i].availability_block_dates]
    const hasNullValue = blockedDates.find(x => (!x.date))
    if (hasNullValue) {
      Swal.fire({
        text: "Please select valid dates",
        icon: "error",
      });
    }
    else {
      blockedDates.push({
        date: ''
      })
      let availabilityStateList = [...availabilityList]
      availabilityStateList[i].availability_block_dates = blockedDates;
      setAvailabilityList(availabilityStateList);
    }
  };
  const removeBlockedDate = (i, availabilityList, blokedIndex) => {
    let availabilityDataInState = [...availabilityList]
    let availability_block_dates = availabilityDataInState[i].availability_block_dates
    availability_block_dates.splice(blokedIndex, 1)
    availabilityDataInState[i].availability_block_dates = availability_block_dates
    setAvailabilityList(availabilityDataInState);
  };
  const removePinkCourts = (i, availabilityList, blokedIndex) => {
    let availabilityDataInState = [...availabilityList]
    let availability_pink_courts = availabilityDataInState[i].availability_pink_courts
    availability_pink_courts.splice(blokedIndex, 1)
    availabilityDataInState[i].availability_pink_courts = availability_pink_courts
    setAvailabilityList(availabilityDataInState);
  };
  const courtValueChange = (e, i, availabilityList, dateIndex) => {
    let blockedDates = [...availabilityList[i].availability_pink_courts]
    blockedDates[dateIndex]['name'] = e.target.value;
    let availabilityStateList = [...availabilityList]
    availabilityStateList[i].availability_pink_courts = blockedDates;
    setAvailabilityList(availabilityStateList);
  };
  const addNewCourt = (i, availabilityList) => {
    let pink_courts = [...availabilityList[i].availability_pink_courts]
    const hasNullValue = pink_courts.find(x => (!x.name))
    if (hasNullValue) {
      Swal.fire({
        text: "Please select valid name",
        icon: "error",
      });
    }
    else {
      pink_courts.push({
        name: ''
      })
      let availabilityStateList = [...availabilityList]
      availabilityStateList[i].availability_pink_courts = pink_courts;
      setAvailabilityList(availabilityStateList);
    }
  };
  const onLocationChanged = (data) => {
    const locationIndex = data.locationIndex
    let availabilityStateList = [...availabilityList]
    availabilityStateList[locationIndex].location_name = data.name;
    availabilityStateList[locationIndex].location_latitude = data.lat;
    availabilityStateList[locationIndex].location_longitude = data.lng;
    setAvailabilityList(availabilityStateList);
  };
  const getLocationObject = () => {
    return {
      location_name: '',
      location_latitude: null,
      location_longitude: null,
      availability_timing: JSON.parse(JSON.stringify([...availabilityData])),
      availability_block_dates: [{ date: '' }],
      availability_pink_courts: [
        { name: '' }
      ]
    }
  }
  const removeLocationItem = async (i, listItem) => {
    let availabilityListInState = [...listItem]
    const selectedItem = availabilityList[i];
    if (selectedItem.id) {
      try {
        const postData = { id: selectedItem.id }
        let resp = await FetchApi(Endpoints.deleteAvailability, postData, 'DELETE');
        if (resp && resp.status === "success") {
          $('#cancelbooking').modal('hide');
          ToastMessage.Success(resp.message);
          availabilityListInState.splice(i, 1)
          setAvailabilityList(availabilityListInState);
        }
        if (resp && resp.message && resp.message.email) {
          Swal.fire({
            text: "This email is not exist",
            icon: "error",
          });
        }
      } catch (e) {
        if (e && e.response && e.response.message && e.response.message.email) {
          Swal.fire({
            text: "This email is already used",
            icon: "error",
          });
        }
      }
    }
    else {
      availabilityListInState.splice(i, 1)
      $('#cancelbooking').modal('hide');
      setAvailabilityList(availabilityListInState)
    }
  }
  const removeLocation = (i, availabilityList) => {
    setSelectedLocation(i)
    $('#cancelbooking').modal('show');
  }
  const addLocation = (availabilityList) => {
    let availabilityListInState = [...availabilityList]
    const item = { ...getLocationObject() }
    availabilityListInState.push(item)
    setAvailabilityList(availabilityListInState)
  }
  const getAvailability = async () => {
    try {
      let resp = await FetchApi(Endpoints.getUserAvailability, { user_slug: props.user.slug });
      if (resp && resp.type === "success") {
        setUserAvailability(resp?.data);
        const avaailabilityList = resp.data
        if (avaailabilityList.length == 0) {
          const item = { ...getLocationObject() }
          avaailabilityList.push(item)
        }
        else {
          avaailabilityList.forEach((item) => {
            let dataSets = []
            const availabilityDataSets = JSON.parse(JSON.stringify(availabilityData))
            availabilityDataSets.forEach((dayInfo) => {
              const mappedItems = item.availability_timing.filter((d) => d.day_name === dayInfo.day)
              let times = []
              let is_day_closed = false;
              mappedItems.forEach((mItem) => {
                times.push({
                  start: mItem.time_from ? mItem.time_from.slice(0, -3) : '',
                  end: mItem.time_to ? mItem.time_to.slice(0, -3) : ''
                })
                if (mItem.time_from) {
                  is_day_closed = true
                }
              })
              dayInfo.times = times
              dayInfo.is_day_closed = is_day_closed ? 1 : 0
              dataSets.push(dayInfo)
            })
            item['availability_timing'] = dataSets
            let availability_day_block = item.availability_day_block
            availability_day_block.forEach((idata) => {
              idata['date'] = idata.blocked_date
            })
            if (availability_day_block.length == 0) {
              availability_day_block = [{ date: '' }]
            }
            item['availability_block_dates'] = availability_day_block;
            item['location_latitude'] = item.latitude
            item['location_longitude'] = item.longitude
            item['availability_pink_courts'] = item.availability_pink_court
          })
        }
        setAvailabilityList(avaailabilityList)
        //console.log(availabilityList)
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  }

  const getEventSegments = (event) => {
    const segments = [];
    let currentStart = moment(event.start);
    const endDateTime = moment(event.end);

    const differenceInDays = endDateTime.diff(currentStart, 'days');

    // console.log(`Difference in days: ${differenceInDays}` + event?.title);
    const startTime = moment(event?.start);
    const endTime = moment(event?.end);
    // const endTime = moment(event?.slot_end_date_time);

    const repeatDays = event?.repeat_days?.split(',')?.map(day => day?.trim()?.toLowerCase());
  
    // console.log('repeatDyzz__', repeatDays)
    while (currentStart.isBefore(endDateTime)) {
      if (event?.recurrences=="weekly" && 
        repeatDays?.includes?.(currentStart?.format('ddd')?.toLowerCase())
        && differenceInDays>7
      ) {
      const nextEnd = currentStart.clone().endOf('day');
      segments.push({
        // ...event,
        // start: currentStart.toDate(),
        // end: nextEnd.isBefore(endDateTime) ? nextEnd.toDate() : event.end,
        ...event,
              start: currentStart.clone().set({
                hour: startTime.hour(),
                minute: startTime.minute(),
                second: startTime.second(),
              }).toDate(),
              end: currentStart.clone().set({
                hour: endTime.hour(),
                minute: endTime.minute(),
                second: endTime.second(),
              }).toDate(),
        additionalParams: {
            duplicateStartTime: event.start,
            duplicateEndTime: event.end,
        },
      });
      }
      else if (event?.recurrences!="weekly" || differenceInDays<8){
        const nextEnd = currentStart.clone().endOf('day');
        segments.push({
          ...event,
          // start: currentStart.toDate(),
          // end: nextEnd.isBefore(endDateTime) ? nextEnd.toDate() : event.end,
          start: currentStart.clone().set({
            hour: startTime.hour(),
            minute: startTime.minute(),
            second: startTime.second(),
          }).toDate(),
          end: currentStart.clone().set({
            hour: endTime.hour(),
            minute: endTime.minute(),
            second: endTime.second(),
          }).toDate(),
          additionalParams: {
              duplicateStartTime: event.start,
              duplicateEndTime: event.end,
          },
        });
      }
      currentStart = currentStart.clone().add(1, 'day').startOf('day');
    }
    return segments;
  };

  const updateBookingCounts = (segments, userBookings) => {
    return segments.map(segment => {
      let coachBookingCount = 0;
      let athleteBookingCount = 0;
      let booked = false;
  
      userBookings.forEach(booking => {
        if (booking.availability_date === moment(segment.start).format('YYYY-MM-DD')) {
          // if(segment?.slot_type == "fully"){
            booked = true
          // }
          if (booking.user.user_role?.id == 3) {
            coachBookingCount++;
          } else if (booking.user.user_role?.id == 2) {
            athleteBookingCount++;
          }
        }
      });

      if(segment?.confirmed_refer_availabilities?.length>0){
        console.log('check__', segment?.confirmed_refer_availabilities?.length)
        coachBookingCount = coachBookingCount + segment?.confirmed_refer_availabilities?.length;
        booked= true
      }
  
      return {
        ...segment,
        coach_booking_count: coachBookingCount,
        athelete_booking_count: athleteBookingCount,
        is_booked: booked
      };
    });
  };

  const formattedData =
      userAvailablity?.map((item) => ({
          title: item?.event_name,
          start: new Date(`${item?.slot_start_date}T${item?.slot_start_time}.0000000`),
          end: new Date(`${item?.slot_end_date}T${item?.slot_end_date_time}.0000000`),
          id: item?.id,
          ...item
      }));

  const segmentedEvents = formattedData?.flatMap((event) => getEventSegments(event));

  const segmentedEventsWithBookingCounts = segmentedEvents.flatMap(segment => 
    updateBookingCounts([segment], segment.user_bookings)
  );
  // console.log('trans__', transformEventData());

  const saveAvailability = async (locationIndex, avaailabilityList) => {
    let dataObject = { ...avaailabilityList[locationIndex] }
    let availability_timing = [];
    let dateObject = []
    if (!dataObject.location_name) {
      Swal.fire({
        text: "Location is required",
        icon: "error",
      });
      return
    }
    dataObject.availability_block_dates.forEach(item => {
      if (item.date) {
        dateObject.push({
          date: moment(
            item.date
          ).format("YYYY/MM/DD")
        })
      }
    })
    dataObject.availability_block_dates = dateObject
    dataObject.availability_timing.forEach(item => {
      if (item.is_day_closed == 1) {
        item.times.forEach(time => {
          availability_timing.push({
            day: item.day, from: time.start, to: time.end, is_day_closed: 1
          })
        })
      }
      else {
        availability_timing.push({
          day: item.day, from: '', to: '', is_day_closed: 0
        })
      }
    })
    dataObject.availability_timing = availability_timing;
    if (props.user.user_role.name !== 'Facilities') {
      delete dataObject.availability_pink_courts
    }
    try {
      let resp = await FetchApi(Endpoints.availability, dataObject);
      if (resp && resp.type === "success") {
        ToastMessage.Success(resp.message);
      }
      else {
        ToastMessage.Error(JSON.stringify(resp.message));
      }
    } catch (e) {
      console.log("availability__err", JSON.stringify(e.response, null, 4));
    }
  };

  const roundToNearestFive = (minutes) => {
    return Math.ceil(minutes / 5) * 5;
  };

  const handleSelectSlot = (slotInfo) => {
    // alert('jhgd')
    const { start, end } = slotInfo;

    const now = new Date();

    if (moment(start).isBefore(now, 'day')) {
      return;
    }

    let startDate, endDate, startTime, endTime;

    if (currentView === 'month') {
      const nextHourTime = dayjs().add(60, 'minutes');
      const roundedMinutes = roundToNearestFive(nextHourTime.minute());
      startDate = moment(start).format('YYYY-MM-DD');
      // endDate = moment(start).format('YYYY-MM-DD');
      if(moment(start).isSame(moment(), 'day')){
        // startTime = dayjs().add(60, 'minutes');
        // endTime = dayjs().add(90, 'minutes');
        startTime = nextHourTime.minute(roundedMinutes).second(0);
        endTime = dayjs().add(90, 'minutes').minute(roundToNearestFive(dayjs().add(90, 'minutes').minute())).second(0);
      }
      else{
        // startTime = dayjs();
        // endTime = dayjs().add(30, 'minutes');
        startTime = dayjs().minute(roundToNearestFive(dayjs().minute())).second(0);
        endTime = dayjs().add(30, 'minutes').minute(roundToNearestFive(dayjs().add(30, 'minutes').minute())).second(0);
      }
    } else {
      startDate = moment(start).format('YYYY-MM-DD');
      // endDate = moment(end).format('YYYY-MM-DD');
      startTime = dayjs(start);
      endTime = dayjs(end);
    }

    setEventDetails({
      startDate,
      endDate,
      startTime,
      endTime
    });

    setModalIsOpen(true);
  };

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  const handleSelectEvent = (event) => {
    // console.log('event___', event)
    const { id, start, end } = event;
    // const startDate = moment(start).format('YYYY-MM-DD');
    const startDate = moment(event?.slot_start_date).format('YYYY-MM-DD');
    // const endDate = moment(end).format('YYYY-MM-DD');
    const endDate = moment(event?.slot_end_date).format('YYYY-MM-DD');
    const startTime = dayjs(start);
    const endTime = dayjs(end);

    setEventDetails({
      id,
      startDate,
      endDate,
      startTime,
      endTime,
      ...event,
      // athlete_fee: event?.athlete_fee,
      // coach_fee: event?.coach_fee,
      // facility_fully_fee: event?.facility_fully_fee ,
      // facility_selected_amenity_id: event?.facility_selected_amenity_id,
      // is_marked_availability: event?.is_marked_availability,
      // max_athlete: event?.max_athlete,
      // max_coaches: event?.max_coaches,
      // max_occupancy: event?.max_occupancy,
      // recurrences: event?.recurrences,
      // slot_type: event?.slot_type,
      // title: event?.title,
      // repeat_days: event?.repeat_days,
      // is_booked: event?.is_booked
    });

    setModalIsOpen(true);
  };

  useEffect(() => {
    getAvailability()
  }, []);

  const CustomDateCellWrapper = ({ value, children }) => {
    const isPast = value < new Date();
    return React.cloneElement(React.Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: isPast ? '#f0f0f0' : 'inherit',
        color: isPast ? '#d3d3d3' : 'inherit',
        pointerEvents: isPast ? 'none' : 'auto',
      },
    });
  };

  const CustomEvent = ({ event, view }) => {
    return (
      <div 
      style={{
        // height: '70px'
        lineHeight: 'normal',
        padding: 0,
        margin: 0,
      }}
      >
        {
          event?.is_marked_availability? 
          <div className="m-0 p-0">
          <strong className="p-0 m-0" style={{fontSize: '12px', color: 'red'}}>{"Marked Available"}</strong>
          </div>
          : 
          null
        }
        <strong className="m-0 p-0" style={{fontSize: '12px'}}>{event.title}</strong>
        {
          event?.slot_type == 'fully' ? 
          (event?.is_booked?
            <div style={{ fontSize: '10px', color: '#888' }}>
            {"(Booked)"}
            </div>
            :
          <div className={view=="month"?"py-0":"py-1"} style={{ fontSize: '10px', color: '#888' }}>
            {"(Full)"}
          </div>)
        :null
        }
        {(event.max_occupancy || event?.max_athlete) && event?.slot_type != 'fully' ? (
          <div className={view=="month"?"py-0":"py-1"} style={{ fontSize: '10px', color: '#888' }}>
            {
            (event?.max_athlete == event?.athelete_booking_count?
              "(Athlete Spots Booked)" :
            `(${(event?.max_athlete - event?.athelete_booking_count)}/` + (event?.max_athlete) + " athlete spots remaining)"
            )
            }
            <br></br>
            {/* {
            (event?.max_coaches == event?.coach_booking_count?
              "(Coach Spots Booked)" :
            `(${(event?.max_coaches - event?.coach_booking_count)}/` + (event?.max_coaches) + " coach spots remaining)"
            )
            } */}
            {event?.max_coaches 
            // && 
            // currentView!="month" 
            ?
            (event?.max_coaches == event?.coach_booking_count?
              <p>
              {"(Coach spots Booked)"}
              </p> :
            <p className="p-0 m-0">
              {`(${(event?.max_coaches - event?.coach_booking_count)}/` + (event?.max_coaches) + " coach spots remaining)"}
            </p>
            )  
            :null
            }
          </div>
        ):null}
      </div>
    );
  };

  return (
    <div className="main py-lg-5 py-4 align-items-start">
      <div 
      style={{
        width: '98%'
      }} className="">
        <div className="row justify-content-center">
          <div className="col-lg-12 col-md-12 custom-calendar">
            <h1 className="fs-24 medium pb-3">Manage Availability 
              {/* <Link className="add-location-link text-green" 
            onClick={(e) => { 
              // addLocation(availabilityList) 
              setModalIsOpen(true);
              }}>
              <i className="far fa-plus-circle"></i> 
              Add More Location
            </Link> */}
            </h1>

            {
              // segmentedEvents?.length>0 &&
              <Calendar
              localizer={localizer}
              selectable
              events={segmentedEventsWithBookingCounts || []}
              startAccessor="start"
              endAccessor="end"
              step={60} // 1 hour intervals
              timeslots={1}
              style={{ height: 600 }}
              showAllEvents={true}
              onView={handleViewChange}
              onSelectSlot={handleSelectSlot}
              onSelectEvent={handleSelectEvent}
              views={['month', 'day', 'week']}
              view={currentView}
              components={{
                toolbar: CustomToolbar,
                dateCellWrapper: CustomDateCellWrapper,
                event: (props) => <CustomEvent {...props} view={currentView} />,
              }}
              // longPressThreshold={10}
              longPressThreshold={0}
            />}
            {/* <form className="site-form" onSubmit={(e) => e.preventDefault()}>
              {availabilityList.map((item, i) => {
                return (<React.Fragment key={i}>
                  <div className="p-md-3 p-2 bg-lightgreen rounded-8 location-window" >
                    {availabilityList.length > 1 && <Link className="text-green fs-18 ms-1 location-delete" onClick={(e) => { removeLocation(i, availabilityList) }}>
                      <i className="far fa-trash"></i>
                    </Link>}
                    <div className="form-field mb-3">
                      <label htmlFor="" className="semibold fs-13 pb-1 d-block">
                        Location {i + 1}
                      </label>
                      <LocationAutocomplete onLocationChanged={onLocationChanged} address={item.location_name} locationIndex={i}></LocationAutocomplete>
                    </div>
                    {props.user.user_role.name === 'Facilities' &&
                      <React.Fragment>
                        <div className="form-field mb-3">
                          <label htmlFor="" className="semibold fs-13 pb-1 d-block">
                            Add Rink/Court
                          </label>
                          {item.availability_pink_courts.map((courtInfo, cIndex) => {
                            return (
                              <div key={cIndex} className={cIndex === 0 ? 'd-flex align-items-center gap-2' : 'd-flex align-items-center gap-2 mt-2'}>
                                <input
                                  onChange={(e) => {
                                    courtValueChange(e, i, availabilityList, cIndex)
                                  }}
                                  type="text"
                                  className="border-0"
                                  value={courtInfo.name}
                                />
                                {item.availability_pink_courts.length > 1 &&
                                  <Link className="text-green fs-18 ms-1" onClick={(e) => { removePinkCourts(i, availabilityList, cIndex) }}>
                                    <i className="far fa-trash me-1"></i>
                                  </Link>
                                }
                                {(item.availability_pink_courts.length - 1) === cIndex &&
                                  <Link onClick={(e) => { addNewCourt(i, availabilityList) }} className="text-green fs-18 px-2">
                                    <i className="far fa-plus-circle"></i>
                                  </Link>}
                              </div>
                            );
                          })}
                        </div>
                      </React.Fragment>
                    }
                    <table className="w-100 available-table">
                      <thead>
                        <tr>
                          <th width="70">Status</th>
                          <th width="140">Days</th>
                          <th>Timing</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          item.availability_timing.map((dayInfo, dIndex) => {
                            return (
                              <tr key={dIndex}>
                                <td>
                                  <div className="form-check form-switch">
                                    <input
                                      onChange={(e) => {
                                        availableChange(e, dIndex, i, availabilityList)
                                      }}
                                      value={1}
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={dayInfo.is_day_closed === 1 ? true : false}
                                      id={dayInfo.day}
                                    />
                                  </div>
                                </td>
                                <td>{dayInfo.dayTitle}</td>
                                <td className="timing-input">
                                  {dayInfo.is_day_closed === 1 && <React.Fragment>
                                    <div>
                                      {dayInfo.times.map((time, timeIndex) => {
                                        return (<div key={timeIndex} className="timing-wrap">
                                          <input
                                            onChange={(e) => timeChange(e, dIndex, i, availabilityList, timeIndex, 'start')}
                                            type="time" value={time.start}
                                          />
                                          <span className="d-inline-block px-2">to</span>
                                          <input
                                            onChange={(e) => timeChange(e, dIndex, i, availabilityList, timeIndex, 'end')}
                                            type="time" value={time.end}
                                          />
                                          {dayInfo.times.length > 1 &&
                                            <Link className="text-green fs-18 ms-1" onClick={(e) => { removeTimes(dIndex, i, availabilityList, timeIndex) }}>
                                              <i className="far fa-trash me-1"></i>
                                            </Link>
                                          }
                                        </div>)
                                      })}
                                    </div>
                                    <Link className="text-green fs-18 ms-1" onClick={(e) => { addTimes(dIndex, i, availabilityList,) }}>
                                      <i className="far fa-plus-circle"></i>
                                    </Link>
                                  </React.Fragment>}
                                </td>
                              </tr>
                            )
                          })
                        }

                      </tbody>
                    </table>
                    <div className="schedule-user position-relative pt-4">
                      <label htmlFor="" className="semibold fs-13 pb-1 d-block">
                        Block Dates
                      </label>
                      {item.availability_block_dates.map((dateItem, index) => {
                        return (
                          <div key={index}
                            style={{
                              marginTop: 10,
                            }}
                            className="d-flex align-items-center gap-2"
                          >
                            <input
                              onChange={(e) => {
                                dateValueChange(e, 'date', i, availabilityList, index)
                              }}
                              value={dateItem.date}
                              type="date"
                              className="rounded-8 custom-date w-auto"
                              placeholder="Date"
                            />
                            {item.availability_block_dates.length > 1 &&
                              <Link className="text-green fs-18 ms-1" onClick={(e) => { removeBlockedDate(i, availabilityList, index) }}>
                                <i className="far fa-trash"></i>
                              </Link>}
                            <Link
                              onClick={() => { addBlockedDates(i, availabilityList) }}
                              className="text-green fs-18 px-2"
                            >
                              <i className="far fa-plus-circle"></i>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={`mt-3 text-center group-butn ${i !== (availabilityList.length - 1) ? 'mb-3': ''}`}>
                    <button type="button" onClick={(e) => saveAvailability(i, availabilityList)} className="butn">
                      Save
                    </button>
                  </div>
                </React.Fragment>)
              })}
            </form> */}
          </div>
        </div>
        {/* <!-- Modal --> */}
        <div className="modal fade"
          id="cancelbooking"
          tabIndex="-1"
          aria-labelledby="sharepostmodal"
          aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Delete Kid</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <p>Are you sure ? Do you want to delete this availability.</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-danger" onClick={(e) => { removeLocationItem(selectedIndex, availabilityList) }}>Delete</button>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Modal --> */}

        <CreateAvailabilityModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        eventDetails={eventDetails}
        setEventDetails={setEventDetails}
        getUserAvailability={getAvailability}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
//export default Header;
export default connect(mapStateToProps, {})(ManageAvailability);