import React, { useEffect, useState } from 'react'
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import moment from "moment";
import { CommonAPi } from "../../API/CommonApi";
import {  useParams, useNavigate } from "react-router-dom";

export default function ViewAda() {
    const [adaTitle, setAdaTitle] = useState("");
    const [adaFor, setAdaFor] = useState("");
    const [adaType, setAdaType] = useState("");
    const [adaStartDate, setAdaStartDate] = useState("");
    const [adaDescription, setAdaDescription] = useState("");
    const [adaEndDate, setAdaEndDate] = useState("");
    const [image, setImage] = useState("");
    const [Id, setId] = useState("");
    const params = useParams();
    const navigate = useNavigate()

    const [adaTitleError, setAdaTitleError] = useState("");
    const [adaForError, setAdaForError] = useState("");
    const [adaTypeError, setAdaTypeError] = useState("");
    const [adaStartDateError, setAdaStartDateError] = useState("");
    const [adaEndDateError, setAdaEndDateError] = useState("");

    useEffect(()=>{
        GetAdsData();
    }, []);

    const GetAdsData = async() =>{
        let data ={
            id:params?.id
        }
        try {
            let resp = await CommonAPi(Endpoints.GetSingalAdvertisements, data);
            if (resp && resp.status === "success") {
                setAdaEndDate(resp?.data?.add_expire_date);
                setAdaStartDate(resp?.data?.add_start_date);
                setAdaType(resp?.data?.add_type);
                setAdaDescription(resp?.data?.description);
                setAdaFor(resp?.data?.add_for);
                setAdaTitle(resp?.data?.add_title);
                setId(resp?.data?.id);
                setImage(resp?.data?.image);
               
            }
        } catch (e) {
            if (e && e.response && e.response.message && e.response.message.email) {
                Swal.fire({
                    text: "This email is already used",
                    icon: "error",
                });
            }
        }
    
    };

    const handleImage = (e) => {
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!adaTitle) {
            setAdaTitleError("Advertisement title is required");
        } else {
            setAdaTitleError("");
        }
        if (!adaFor) {
            setAdaForError("Advertisement for is required");
        } else {
            setAdaForError("");
        }
        if (!adaType) {
            setAdaTypeError("Advertisement type is required");
        } else {
            setAdaTypeError("");
        }
        if (!adaStartDate) {
            setAdaStartDateError("Advertisement start date is required");
        } else {
            setAdaStartDateError("");
        }
        if (!adaEndDate) {
            setAdaEndDateError("Advertisement start end is required");
        } else {
            setAdaEndDateError("");
        }


        if (adaTitle && adaFor && adaType && adaStartDate && adaEndDate) {
            const formData = new FormData();
            formData.append("add_title", adaTitle);
            formData.append("id", Id);
            formData.append("add_for", adaFor);
            formData.append("add_type", adaType);
            formData.append("add_start_date", moment(adaStartDate).format("YYYY/MM/DD"));
            formData.append("add_expire_date", moment(adaEndDate).format("YYYY/MM/DD"));
            if (image) {
                formData.append("image", image);
            }
            try {
                let resp = await CommonAPi(Endpoints.CreateAdvertisement, formData);
                if (resp && resp.status === "success") {
                    setImage("");
                    setAdaEndDate("");
                    setAdaStartDate("");
                    setAdaType("");
                    setAdaFor("");
                    setAdaTitle("");
                    Swal.fire({
                        text: "Advertisement Updated Successfully",
                        icon: "success",
                    });
                    navigate('/manage-advertisement')
                }
            } catch (e) {
                if (e && e.response && e.response.message && e.response.message.email) {
                    Swal.fire({
                        text: "This email is already used",
                        icon: "error",
                    });
                }
            }
        }
    };
    return (
        <div className="wrapper">
            <div className="main py-5">
                <div className="container">
                    <div className="row justify-content-center ">
                        <div className="col-lg-6 col-md-8">
                            <h1 className="text-darkgray semibold pb-2 text-center fs-24">
                                View Advertisement
                            </h1>

                            <form className="site-form pt-2">
                                <div className="form-field mb-3">
                                    <label htmlFor="" className="pb-1">
                                        Advertisement Title
                                    </label>
                                    <input
                                        value={adaTitle}
                                        disabled={true}
                                        type="text"
                                        placeholder="Enter Advertisement Title"
                                    />
                                   
                                </div>
                                <div className="form-field mb-3">
                                    <label  className="pb-1">
                                        Advertisement For
                                    </label>
                                    <select  
                                       disabled={true}
                                    >
                                        <option>Select Advertisement For</option>
                                        <option value={"athelete"} selected={adaFor == "athelete" ? true : false}>Athlete</option>
                                        <option value={"coach"} selected={adaFor == "coach" ? true : false}>Coach</option>
                                        <option value={"everyone"} selected={adaFor == "everyone" ? true : false}>Everyone</option>
                                    </select>

                                </div>
                                <div className="form-field mb-3">
                                    <label  className="pb-1">
                                        Advertisement Type
                                    </label>
                                    <select
                                    disabled={true}
                                    >
                                        <option>Select Advertisement Type</option>
                                        <option value={"promotion"} selected={adaType == "promotion" ? true : false}>Promotion</option>
                                        <option value={"lead"} selected={adaType == "lead" ? true : false}>Lead</option>
                                    </select>
                                    
                                    
                                </div>

                                {/* <div className="form-field mb-3">
                                    <label  className="pb-1">
                                        Advertisement Description
                                    </label>
                                    <textarea
                                        disabled={true}
                                        value={adaDescription}
                                    />
                                </div> */}

                                <div className="form-field mb-3">
                                    <label htmlFor="" className="pb-1">
                                        Advertisement Start Date
                                    </label>
                                    <input
                                        type="date"
                                        value={adaStartDate}
                                        disabled={true}
                                    />
                                </div>
                                <div className="form-field mb-3">
                                    <label htmlFor="" className="pb-1">
                                        Advertisement End Date
                                    </label>
                                    <input
                                        type="date"
                                        value={adaEndDate}
                                        disabled={true}
                                    />
                                    
                                </div>

                                <div className="form-field mb-3">
                                    <label htmlFor="" className="pb-1">
                                        Advertisement Image
                                    </label>
                                    <div>
                                    <img src={image} alt='' width={150} />
                                    </div>
                                 
                                    
                                </div>



                                <button
                                    type="button"
                                    onClick={() => navigate("/manage-advertisement")}
                                    className="w-100 gray-butn butn  rounded-pill border-0"
                                >
                                    Back
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
