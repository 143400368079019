import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer py-md-4 py-3">
      <div className="container">
        <div className="row justify-content-between pt-md-3">
          <div className="col-lg-4 col-md-5">
            <ul className="footer-menu fs-16 medium d-flex justify-content-between gap-2">
              {/* <li>
                <Link>About us</Link>
              </li>
              <li>
                <Link>Our Team</Link>
              </li> */}
              <li>
                <Link 
                to={'/contact-us'}
                // to={"https://docs.google.com/forms/d/e/1FAIpQLScYWQvC_b6yUh5i19VgG2UuS1ZiYe61g4uBmLMAIXpi8QxKGA/viewform"} 
                // target='_blank'
                >Contact us</Link>
              </li>
            </ul>
          </div>

          <div className="col-md-5 text-end">
            <ul className="footer-social d-flex gap-md-4 gap-3 justify-content-md-end justify-content-center fs-22 pt-md-0 pt-3">
              <li>
                <Link>
                  <i className="fab fa-facebook-square"></i>
                </Link>
              </li>
              <li>
                <Link>
                  <i className="fab fa-twitter"></i>
                </Link>
              </li>
              <li>
                <Link>
                  <i className="fab fa-youtube"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="text-center footer-logo position-relative">
          <a href="#!" className="d-inline-block bg-white">
            <img src="../assets/img/footer-logo.svg" alt="" />
          </a>
        </div>

        <div className="row">
          <div className="col-md-6">
            <p className="text-gray m-0">
              Copyright © 2023 Atrio All Rights Reserved
            </p>
          </div>

          <div className="col-md-6 text-md-end footer-menu fs-14 pt-md-0 pt-2">
            {/* <a href="#" > */}
            <Link className="pe-4">Terms of Service </Link>
            {/* </a> */}
            <Link>Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
