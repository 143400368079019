import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { Cookies } from "react-cookie";
import { logoutUser } from '../redux/actions';
import { FetchApi } from "../API/FetchApi";
import { Endpoints } from "../API/Endpoints";
import { getCountChangeStatus } from "../helpers/authUtils";
import { useGetChatCount } from "../Services/query";
import { useQueryClient } from "@tanstack/react-query";
function Header(props) {
  const history = useNavigate();
  const userSlug = useLocation();
  const userPath = userSlug.pathname;
  const [count, setCount] = useState(0);
  const {data: chatCount} = useGetChatCount();
  const queryClient = useQueryClient();
  const truncate = (str, n) => {
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
  };

  // console.log('getCou__', JSON.stringify(chatCount, null, 4))
  const logOut = async() => {
    try {
      let resp = await FetchApi(Endpoints.Logout, {});
      if (resp && resp.type === "success") {
        let cookies = new Cookies();
        cookies.remove("user", { path: "/" });
        cookies.remove("token", { path: "/" });
        props.logoutUser(history)
        history('/login')
      }
    }
    catch (e) {
      // // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
    

  };

  
  useEffect(() => {
    // getChatCount();
    queryClient.invalidateQueries('chat_count');
  }, [userPath]);

  // console.log('use__', userPath)
  const getChatCount = async () => {
    try {
      let resp = await FetchApi(Endpoints.GetChatCounter, {});
      if (resp && resp.type === "success") {
        setCount(resp?.data?.totalPendingMessages)
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  };
  return (
    <div className="header border-bottom">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-2 col-md-3 col-4 header-logo">
            <div className="site-logo">
              <Link to={props.user ? '/feeds':'/'}>
                <img src="../assets/img/site-logo.svg" alt="" />
              </Link>
            </div>
          </div>
          <div className="col-lg-10 col-md-9 col-8 text-end header-right">
            <div className="d-flex align-items-center justify-content-end">
              <Link
                className="d-lg-none menu-toggle"
                id="toggle_menu"
                data-bs-toggle="dropdown"
              >
                <i className="far fa-bars"></i>
              </Link>
              {props.user &&
                <React.Fragment>

                  <div
                    className="nav_area d-lg-block  dropdown-menu usermenu border-0"
                    aria-labelledby="toggle_menu"
                  >
                    <ul className="menu d-lg-flex gap-xl-5 gap-md-5 gap-0 align-items-center">
                      <li>
                        <Link className="text-green" to={'/feeds'}>
                          <img src="../assets/img/icon/feed.svg" alt="" />
                          Home
                        </Link>
                      </li>
                      {props?.user?.user_role.name !== 'Facilities' &&
                        <li>
                          <Link className="text-green" to={'/facilities'}>
                            <img src="../assets/img/icon/facilities.svg" alt="" />
                            Facilities
                          </Link>
                        </li>
                      }

                      {props?.user?.user_role.name === 'User' &&
                        <li>
                          <Link className="text-green" to={"/coaches"}>
                            <img src="../assets/img/icon/coaches.svg" />Coaches
                          </Link>
                        </li>
                      }

                      <li>
                        <Link className="text-green" to={'/my-schedule'}>
                          <img src="../assets/img/icon/schedule.svg" alt="" />
                          My Schedule
                        </Link>
                      </li>
                      {props?.user?.user_role?.name == 'Facilities' &&
                        <React.Fragment>
                          <li>
                            <Link className="text-green" to={"/manage-advertisement"} >
                              <img src="../assets/img/icon/schedule.svg" alt="" />
                              Manage Ads
                            </Link>
                          </li>
                          <li>
                            <Link className="text-green" to={'/manage-job'}>
                              <img src="../assets/img/icon/facilities.svg" alt="" />
                              Manage Jobs
                            </Link>
                          </li>
                        </React.Fragment>
                      }
                    </ul>
                  </div>
                  

                  <Link to={"/message"} className="head-icon ms-lg-4 ms-md-3 ms-2">
                    <img src="../assets/img/icon/messages1.svg" alt="" />
                    
                    {/* {count ? <span className="">{count}</span> : "" } */}
                    {chatCount?.totalPendingMessages ? <span className="">{chatCount?.totalPendingMessages}</span> : "" }
                  </Link>
                  <div className="d-flex align-items-center justify-content-end px-md-3 px-2 active-menu ms-md-3 ms-2 head-profile">
                    <Link
                      className="d-block"
                      id="head_profile"
                      data-bs-toggle="dropdown"
                    >
                      <span className="fs-15 medium ps-2 d-none d-xl-inline-block name-span">
                        {props.user ? truncate('Hi '+props.user.name, 7) : "Hello"}
                      </span>
                      <span className="user-pp-top-nav mx-xl-2 me-md-2 me-1 ">
                        <img
                          src={props.user && props.user.image ? props.user.image : '../assets/img/profile.png'}
                          alt=""

                          className="rounded-circle dp-border header-dp"
                        />
                      </span>
                      <img src="../assets/img/icon/bars.svg" width="26" alt="" />
                    </Link>

                    <div
                      className="profile-dropdown dropdown-menu dropdown-menu-end p-0"
                      aria-labelledby="head_profile"
                    >
                      <ul>
                        <li 
                        className="c-p"
                        onClick={()=>{
                          history(props?.user?.user_role.name != 'User' ? (props.user.user_role.name === 'Coach' ? '/coache-profile' : 'facility-profile') : '/profile')
                        }}
                        >
                          <Link 
                          // to={props.user.user_role.name != 'User' ? (props.user.user_role.name === 'Coach' ? '/coache-profile' : 'facility-profile') : '/profile'}
                          >
                            <img
                              src="../assets/img/icon/profile.svg"
                              width="25"
                              height="25"
                              className="me-2"
                              alt=""
                            />
                            My Profile
                          </Link>
                        </li>
                        <li
                        className="c-p"
                        onClick={()=>{
                          history('/my-bookings')
                        }}
                        >
                          <Link 
                          // to={'/my-bookings'}
                          >
                            <img
                              src="../assets/img/icon/clipboardtick.svg"
                              width="25"
                              height="25"
                              className="me-2"
                              alt=""
                            />
                            My Bookings
                          </Link>
                        </li>
                        {props?.user?.user_role?.name !== 'User' &&

                          <li
                          className="c-p"
                          onClick={()=>{
                            history('/manage-availability')
                          }}
                          >
                            <Link 
                            // to={"/manage-availability"}
                            >
                              <img
                                src="../assets/img/icon/calendar2.svg"
                                width="25"
                                height="25"
                                className="me-2"
                                alt=""
                              />
                              Manage Availability
                            </Link>
                          </li>
                        }
                        {props?.user?.user_role?.name != 'User' ?
                        <li
                        className="c-p"
                          onClick={()=>{
                            history('/payment')
                          }}
                        >
                          <Link 
                          // to={"/payment"}
                          >
                            <img
                              src="../assets/img/icon/card.svg"
                              width="25"
                              height="25"
                              className="me-2"
                              alt=""
                            />
                            Payments
                          </Link>
                        </li>
                        :""
                        }
                        {props?.user?.user_role?.name == 'User' ||
                        props?.user?.user_role?.name == 'Coach' ?
                        <li
                        className="c-p"
                        onClick={()=>{
                          history('/reward-points')
                        }}
                        >
                          <Link 
                          // to={"/reward-points"}
                          >
                            <img
                              src="../assets/img/icon/star1.svg"
                              width="25"
                              height="25"
                              className="me-2"
                              alt=""
                            />
                            Reward & Points  
                          </Link>
                        </li>
                        :""
                        }

                        {props?.user?.user_role?.name == 'User' ||
                        props?.user?.user_role?.name == 'Coach' ?
                        <li
                        className="c-p"
                        onClick={()=>{
                          history('/job-listing')
                        }}
                        >
                          <Link 
                          // to={"/job-listing"}
                          >
                            <img
                              src="../assets/img/icon/facilities.svg"
                              width="25"
                              height="25"
                              className="me-2"
                              alt=""
                            />
                            View Jobs
                          </Link>
                        </li>
                        :""
                        }

                        {props?.user?.user_role?.name == 'User' &&
                          <li
                          className="c-p"
                          onClick={()=>{
                            history('/manage-kids')
                          }}
                          >
                            <Link 
                            // to={"/manage-kids"}
                            >
                              <img
                                src="../assets/img/icon/manage-kid.svg"
                                width="25"
                                height="25"
                                className="me-2"
                                alt=""
                              />
                              Manage Athletes
                            </Link>
                          </li>
                        }
                        <li
                        className="c-p"
                        onClick={()=>{
                          history('/change-password')
                        }}
                        >
                          <Link 
                          // to={"/change-password"}
                          >
                            <img
                              src="../assets/img/icon/setting2.svg"
                              width="25"
                              height="25"
                              className="me-2"
                              alt=""
                            />
                            Settings
                          </Link>
                        </li>
                        <li
                        className="c-p"
                        onClick={()=>{
                          logOut()
                        }}
                        >
                          <a 
                          // onClick={() => logOut()} className="c-p"
                          >
                            <img
                              src="../assets/img/icon/logout.svg"
                              width="25"
                              height="25"
                              className="me-2"
                              alt=""
                            />
                            Log Out
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
               
                </React.Fragment>
              }
              {!props.user &&
                <React.Fragment>
                  <div
                    className="nav_area d-lg-block  dropdown-menu border-0"
                    aria-labelledby="toggle_menu"
                  >
                    <ul className="menu d-lg-flex gap-xl-5 gap-md-3 gap-0 align-items-center">
                      <li>
                        <Link to={'/'}>Home</Link>
                      </li>
                      {/* <li>
                        <Link>About Us</Link>
                      </li>
                      <li>
                        <Link>Our Team</Link>
                      </li> */}
                      <li>
                        <Link 
                        to={'/contact-us'}
                        // to={"https://docs.google.com/forms/d/e/1FAIpQLScYWQvC_b6yUh5i19VgG2UuS1ZiYe61g4uBmLMAIXpi8QxKGA/viewform"} 
                        // target='_blank'
                        >Contact us</Link>
                      </li>
                      {(userPath != "/user-complete-profile" && userPath != "/coach-profile-complete" && userPath != "/facility-profile-complete") &&
                        <li className="login-btn">
                          <Link className="butn me-3" to={"/login"}>
                            Login
                          </Link>
                          <Link className="butn " to={"/signup"}>
                          {/* <Link className="butn " 
                          to={"https://docs.google.com/forms/d/e/1FAIpQLScYWQvC_b6yUh5i19VgG2UuS1ZiYe61g4uBmLMAIXpi8QxKGA/viewform"} 
                          target='_blank'
                          > */}
                            Sign Up
                          </Link>
                        </li>
                      }

                    </ul>
                  </div>
                </React.Fragment>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
//export default Header;
export default connect(mapStateToProps, { logoutUser })(Header);
