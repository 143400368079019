import React, { useEffect } from "react";
import { Link } from "react-router-dom";
const $ = window.jQuery;
function LandingPage() {
  useEffect(() => {
    /* $('.owl-carousel').owlCarousel({
      items: 2
    }) */
    $(".play-video").click(function () {
      $('.feed-video video').trigger('play').attr('controls', '');
      $(this).fadeOut();
    });
    $('.video-slider').owlCarousel({
      loop: true,
      margin: 18,
      responsiveClass: true,
      nav: true,
      navText: ['<i class="fa-light fa-chevron-left text-white fs-10"></i>', '<i class="fa-light fa-chevron-right text-white fs-10"></i>'],
      dots: false,
      loop: false,
      responsive: {
        0: {
          items: 1,
        },
        575: {
          items: 1,
        },
        767: {
          items: 2,
          nav: true,
        },
        850: {
          items: 2
        },
        1150: {
          items: 2
        }
      }
    })
  })
  return (<React.Fragment>
    <div className="main d-block">
      <div className="home-banner">
        <div className="container pb-5">
          <div className="row align-items-center pb-md-5">
            <div className="col-md-7 left-side position-relative pt-md-0 pt-4">
              <h1 className="text-black pb-2">
                <span className="bold text-green">Atrio</span>-The Sports
                Ecosystem
              </h1>
              <h2 className="text-black pb-3 fs-sm-22">
                “Youth sports is finally out of the stone age.”
              </h2>
              <p className="text-green2">
                Youth sports is bigger than ever, but is riddled with problems
                that coaches, athletes, parents and sports facilities face
                everyday.
              </p>

              <p className="text-green2">
                Let’s explore these problems and how Atrio solves them.
              </p>
              <div className="butn-group d-flex gap-2 align-items-center">
                <Link className="butn px-lg-5 d-block" onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("partent-player").scrollIntoView();
                }}>
                  Parents & Players
                </Link>
                <Link className="butn px-lg-5 d-block" onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("coaches").scrollIntoView();
                }}>Coaches</Link>
                <Link className="butn px-lg-5 d-block" onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("facility").scrollIntoView();
                }}>Facilities</Link>
              </div>
              <h3 className="fs-26 bold text-green3 fst-italic pt-3 fs-sm-22">
                Atrio Sports. Be Better at Getting Better.
              </h3>
            </div>
            <div className="col-md-5">
              <img src="assets/img/banner-img.png" className="w-100" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="parent-section py-5">
        <div className="container">
          <div className="row align-items-center" id="partent-player">
            <div className="col-md-9 left-side position-relative">
              <h3 className="fs-26 bold text-green3 fst-italic text-decoration-underline pb-2">
                Parents & Players
              </h3>
              <h2 className="fs-30 semibold pb-4 fs-sm-22">
                Don’t accept average coaching. <br />
                Find the best, with Atrio
                {/* </br> */}
              </h2>
              <h3 className="fs-22 regular text-green3 fst-italic text-decoration-underline pb-4 fs-sm-18">
                Features / Benefits - all in our Freemium profile.
              </h3>
              <p>
                Search top rated coaches and facilities, all of whom have
                background checks and verified certifications
              </p>

              <p>
                Direct message, search schedules and book lessons with any
                coach or facility in your area
              </p>

              <p>Pay digitally via debit, credit or rewards</p>

              <p>
                Automated reminders and notifications on all your digital
                devices
              </p>

              <p>
                Integrate us onto Google, Outlook or other external calendars!
              </p>
              <Link 
              to={"https://docs.google.com/forms/d/e/1FAIpQLScYWQvC_b6yUh5i19VgG2UuS1ZiYe61g4uBmLMAIXpi8QxKGA/viewform"} 
              target='_blank'
              className="butn rounded-pill mt-4">
                Learn More{" "}
                <i className="fa-regular fa-arrow-right-long ms-2"></i>
              </Link>
              <Link className="butn rounded-pill mt-4 ms-2" 
              to={"/signup"}
              // to={"https://docs.google.com/forms/d/e/1FAIpQLScYWQvC_b6yUh5i19VgG2UuS1ZiYe61g4uBmLMAIXpi8QxKGA/viewform"} 
              // target='_blank'
              >
                Sign Up
              </Link>
            </div>
            <div className="col-md-3 cocha-type">
              <h3 className="fst-italic semibold pb-3 pt-md-0 pt-3">
                Types of coaches
              </h3>
              <ul>
                <li>Sport-specific Coaches</li>
                <li>Skills Coaches</li>
                <li>Sports Organizations</li>
                <li>Youth Programs</li>
                <li>Physical Trainers</li>
                <li>Nutrition Experts</li>
                <li>Therapists</li>
                <li>Tutors</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="coache-section py-5 position-relative mb-5">
        <div className="container">
          <div className="row align-items-center" id="coaches">
            <div className="col-lg-5 left-side position-relative">
              <h3 className="fs-26 bold text-green3 fst-italic text-decoration-underline pb-2">
                Coaches
              </h3>
              <h2 className="fs-30 semibold pb-4 fs-sm-22">
                Your time, your money, your coaching career - at your
                fingertips
              </h2>
              <h3 className="fs-22 regular text-green3 fst-italic text-decoration-underline pb-4 fs-sm-18">
                Features / Benefits
              </h3>
              <p>
                Find parents looking to upgrade / expand their children’s
                coaching
              </p>

              <p>
                Book lessons, get paid and get automated reminders on all
                devices through Atrio
              </p>

              <p>Manage your hours, locations and earnings.</p>
              <Link 
              to={"https://docs.google.com/forms/d/e/1FAIpQLScYWQvC_b6yUh5i19VgG2UuS1ZiYe61g4uBmLMAIXpi8QxKGA/viewform"} 
              target='_blank'
              className="butn rounded-pill mt-md-4 mt-2 mb-md-0 mb-2">
                Learn More{" "}
                <i className="fa-regular fa-arrow-right-long ms-2"></i>
              </Link>
              <Link className="butn rounded-pill mt-md-4 mt-2 mb-md-0 mb-2 ms-2" 
              to={"/signup"}
              // to={"https://docs.google.com/forms/d/e/1FAIpQLScYWQvC_b6yUh5i19VgG2UuS1ZiYe61g4uBmLMAIXpi8QxKGA/viewform"} 
              // target='_blank'
              >
                Sign Up
              </Link>
            </div>

            <div className="col-lg-7 right-side d-md-flex align-items-center pt-lg-0 pt-3">
              <img
                src="assets/img/coache-img.png"
                className="coache-right-img"
                alt=""
              />
              <div>
                <h3 className="semibold text-green fst-italic px-4 pb-md-4 pt-md-0 pb-3 pt-3">
                  Testimonials
                </h3>
                <div className="d-flex align-items-center testimonial mb-5">
                  <img
                    src="assets/img/testimonial-1.png"
                    className="rounded-circle"
                    width="70"
                    height="70"
                    alt=""
                  />
                  <div className="content bg-light green">
                    <h6 className="pb-3 text-green2 regular">
                      “I don’t need to worry about constantly going back and
                      forth on availability - my calendar stays up to the
                      minute!”
                    </h6>
                    <h5 className="medium">Esther Howard</h5>
                    <span>Football Coach</span>
                  </div>
                </div>
                <div className="d-flex align-items-center testimonial m-0">
                  <div className="content bg-light green testimonial-right-content">
                    <h6 className="pb-3 text-green2 regular">
                      “I found 5 new clients in my first month using Atrio”
                    </h6>
                    <h5 className="medium">Megan Elara</h5>
                    <span>Tennis Coach</span>
                  </div>
                  <img
                    src="assets/img/testimonial-1.png"
                    className="testimonial-right-img rounded-circle"
                    width="70"
                    height="70"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="facility-section position-relative">
        <div className="container" id="facility">
          <div className="row ">
            <div className="col-lg-7 text-white left-side position-relative">
              <h3 className="fs-26 bold text-white fst-italic text-decoration-underline pb-2 pt-3">
                Facilities
              </h3>
              <h2 className="fs-30 semibold pb-4 fs-sm-22">
                Your operations, your staff your financials - managed with no
                effort
              </h2>
              <h3 className="fs-22 regular text-white fst-italic text-decoration-underline pb-4">
                Features / Benefits
              </h3>
              <p>
                Manage operations instantly online and update schedules with a
                click of a button
              </p>
              <p>
                Get paid as slots are booked - no chasing what you’re owed
              </p>
              <p>
                Less time managing schedules & operations, more time
                completing other tasks!{" "}
              </p>
              <Link 
              to={"https://docs.google.com/forms/d/e/1FAIpQLScYWQvC_b6yUh5i19VgG2UuS1ZiYe61g4uBmLMAIXpi8QxKGA/viewform"} 
              target='_blank'
              className="butn rounded-pill mt-2 text-white border mb-4">
                Learn More{" "}
                <i className="fa-regular fa-arrow-right-long ms-2"></i>
              </Link>
              <Link className="butn rounded-pill mt-2 text-white border mb-4 ms-2" 
              to={"/signup"}
              // to={"https://docs.google.com/forms/d/e/1FAIpQLScYWQvC_b6yUh5i19VgG2UuS1ZiYe61g4uBmLMAIXpi8QxKGA/viewform"} 
              // target='_blank'
              >
                Sign Up
              </Link>
            </div>
            <div className="col-lg-5">
              <div className="row h-50 align-items-center slider-section position-relative m-lg-0 px-5 py-lg-0 py-3">
                <div className="owl-theme owl-carousel video-slider pt-md-0 pt-4">
                  <div className="video-item">
                    <div className="feed-video position-relative">
                      {/* <video width="100%" className="d-block rounded-8">
                        <source src="assets/img/video.mp4" type="video/mp4" />
                      </video> */}
                      <img src="/assets/img/excel.png"/>
                      {/* <Link
                        href="#!"
                        className="play-video position-absolute w-100 h-100 align-items-center justify-content-center top-0"
                      >
                        <img
                          alt=""
                          src="assets/img/icon/green-video-play.svg"
                          width="40"
                          height="40"
                        />
                      </Link> */}
                    </div>
                    {/* <h5 className="medium py-2 fs-14 text-white">
                      Lorem ipsum dolor sit amet
                    </h5> */}
                    <h6 className="fs-12 regular text-white pb-md-0 pb-4 pt-2">
                      Customize and publish your schedule for the public to view and book directly on Atrio
                    </h6>
                  </div>
                  <div className="video-item">
                    <div className="feed-video position-relative">
                      {/* <video width="100%" className="d-block rounded-8">
                        <source src="assets/img/excel.png" type="video/mp4" />
                      </video> */}
                      <img src="/assets/img/dashb.png"/>
                      {/* <Link
                        href="#!"
                        className="play-video position-absolute w-100 h-100 align-items-center justify-content-center top-0"
                      >
                        <img
                          alt=""
                          src="assets/img/icon/green-video-play.svg"
                          width="40"
                          height="40"
                        />
                      </Link> */}
                    </div>
                    {/* <h5 className="medium py-2 fs-14 text-white">
                      Lorem ipsum dolor sit amet
                    </h5> */}
                    <h6 className="fs-12 regular text-white pb-md-0 pb-4 pt-2">
                      Build your profile to promote your amenities and interact with your customers
                    </h6>
                  </div>
                  {/* <div className="video-item">
                    <div className="feed-video position-relative">
                      <video width="100%" className="d-block rounded-8">
                        <source src="assets/img/video.mp4" type="video/mp4" />
                      </video>
                      <Link
                        href="#!"
                        className="play-video position-absolute w-100 h-100 align-items-center justify-content-center top-0"
                      >
                        <img
                          alt=""
                          src="assets/img/icon/green-video-play.svg"
                          width="40"
                          height="40"
                        />
                      </Link>
                    </div>
                    <h5 className="medium py-2 fs-14 text-white">
                      Lorem ipsum dolor sit amet
                    </h5>
                    <h6 className="fs-12 regular text-white pb-md-0 pb-4">
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium
                    </h6>
                  </div> */}
                </div>
              </div>
              <div className="row align-items-center h-50 support-section  position-relative m-lg-0">
                <img
                  src="assets/img/support-img.png"
                  className="w-100 h-100 p-0"
                  alt=""
                />

                <div className="col-6 text-center text-white support-content py-lg-0 py-4">
                  <h5 className="pb-3">Dedicated Support</h5>
                  <Link className="butn rounded-pill mb-3 text-white border"
                  to={"/signup"}
                  // to={"https://docs.google.com/forms/d/e/1FAIpQLScYWQvC_b6yUh5i19VgG2UuS1ZiYe61g4uBmLMAIXpi8QxKGA/viewform"} 
                  // target='_blank'
                  >
                    Sign Up{" "}
                  </Link>
                  <h6 className="regular">
                    Call or email anytime and we promise we’ll get back to you
                    within 24 hours!
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="team-section py-5">
        <div className="container">
          <h3 className="semibold text-dark gray pt-md-4 pb-md-5 pb-3 text-center">
            Meet Our Team
          </h3>
          <div className="row text-center pt-md-3 justify-content-center">
            <div className="col-md-9">
              <div className="row d-flex justify-content-center">
                <div className="col-md-4 pb-md-0 pb-4">
                  <img style={{
                    borderRadius: '50%',
                    objectFit: 'cover',
                    width: '200px',
                    height: '200px'

                  }}src="assets/img/josh.jpeg" alt="" />
                  <h4 className=" pt-3 text-black">Josh Branchflower</h4>
                  <span className="text-gray pb-1 d-block">Founder & CEO</span>
                  <div className="d-flex gap-3 justify-content-center">
                    <Link>
                      <i className="fa-brands fa-facebook fs-18"></i>
                    </Link>
                    <Link>
                      <i className="fa-brands fa-twitter fs-18"></i>
                    </Link>
                    <Link>
                      <i className="fa-brands fa-linkedin fs-18"></i>
                    </Link>
                  </div>
                </div>
                <div className="col-md-4 pb-md-0 pb-4">
                  <img style={{
                    borderRadius: '50%',
                    objectFit: 'cover',
                    width: '200px',
                    height: '200px'

                  }} src="assets/img/autsin.jpeg" alt="" />
                  <h4 className=" pt-3 text-black">Austin Essery</h4>
                  <span className="text-gray pb-1 d-block">Co-Founder & CFO</span>
                  <div className="d-flex gap-3 justify-content-center">
                    <Link>
                      <i className="fa-brands fa-facebook fs-18"></i>
                    </Link>
                    <Link>
                      <i className="fa-brands fa-twitter fs-18"></i>
                    </Link>
                    <Link>
                      <i className="fa-brands fa-linkedin fs-18"></i>
                    </Link>
                  </div>
                </div>
                {/* <div className="col-md-4">
                  <img src="assets/img/member-3.png" alt="" />
                  <h4 className=" pt-3 text-black">Robert Fox</h4>
                  <span className="text-gray pb-1 d-block">Lorem Ipsum</span>
                  <div className="d-flex gap-3 justify-content-center">
                    <Link>
                      <i className="fa-brands fa-facebook fs-18"></i>
                    </Link>
                    <Link>
                      <i className="fa-brands fa-twitter fs-18"></i>
                    </Link>
                    <Link>
                      <i className="fa-brands fa-linkedin fs-18"></i>
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-top position-relative pt-lg-5 pt-4">
      <div className="container subscribe rounded-10 px-3 py-4 mb-5  ">
        <div className="row  align-items-center">
          <div className="col-lg-9 col-md-6 text-md-start text-center ps-lg-5">
            <h2 className="fs-sm-22 text-darkgray demibold">Request More Information</h2>
          </div>
          <div className="col-lg-3 col-md-6 px-lg-5 text-md-end text-center">
            <Link 
            // to={'/contact-us'} 
            to={"https://docs.google.com/forms/d/e/1FAIpQLScYWQvC_b6yUh5i19VgG2UuS1ZiYe61g4uBmLMAIXpi8QxKGA/viewform"} 
            target='_blank'
            className="butn rounded-pill border-0">Learn More <i className="fa-regular fa-arrow-right-long ms-2"></i></Link>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
  );
}

export default LandingPage;
