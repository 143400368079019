import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Endpoints } from "../../API/Endpoints";
import { FetchApi } from "../../API/FetchApi";
import { CommonAPi } from "../../API/CommonApi";
import { ValidateList, ValidationTypes } from "../../Utils/ValidationHelper";
import ToastMessage from "../../Utils/ToastMessage";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { getAddressDetails, mapConfigs } from '../../helpers/authUtils'
import Select from 'react-select'
import { useEffect } from "react";
import LocationAutocomplete from "../../Components/LocationAutocomplete";
import { loginUserSuccess } from '../../redux/actions';
import { connect } from "react-redux";
import { uuidv4 } from "../../Utils/constants";
const libraries = ["places"];

function FacilityProfileComplete(props) {
 
  const history = useNavigate();
  const userSlug = useLocation();
  const stateParam = userSlug.state
  const [isEdit, setPageMode] = useState(stateParam?.edit ? true : false)
  const user = isEdit ? stateParam.user : stateParam;
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(isEdit ? user?.phone : user?.mobile);
  const [zip, setZip] = useState(isEdit ? user.zip_code : '');
  // let fullNam = isEdit ? user?.name : user?.fname + user?.lname;
  let fullNam = isEdit ? user?.name : user?.fname;
  const [select, setSelect] = useState(0);
  const [fullName, setFullName] = useState(fullNam);
  const [password, setPassword] = useState("");
  const [speciality, setSpeciality] = useState([]);
  const [category, setCategory] = useState(isEdit ? user.profileData.category_id : '');
  const [state, setState] = useState(isEdit ? user.state : '');
  const [confirmPassword, setConfirmPassword] = useState("");
  const [countryCode, setCountryCode] = useState(isEdit ? user?.phone_code : user?.phone_code);
  const [about, setAbout] = useState(isEdit ? user.about : '');
  const [country, setCountry] = useState(isEdit ? user.country : '');
  const [address, setAddress] = useState(isEdit ? user.address : '');
  const [city, setCity] = useState(isEdit ? user.city : '');
  const [gender, setGender] = useState(isEdit ? user.gender : '');
  const [price, setPrice] = useState(isEdit ? user?.atrioPrice?.calculatedPrice : '');
  const [imageData, setImageData] = useState("");
  const [certificate, setCertificate] = useState(isEdit ? user.user_certificate : []);
  const [image, setImage] = useState(isEdit ? user?.image : null);
  const [picture, setPicture] = useState(isEdit ? user.facility_image : []);
  const [facility, setfacility] = useState([]);
  const [pictureData, setpictureData] = useState([]);
  const [certificateData, setcertificateData] = useState([]);
  const [latitude, setLatitude] = useState(isEdit ? user.latitude : "");
  const [longitude, setLongitude] = useState(isEdit ? user.longitude : "");
  const mapConfig = mapConfigs()
  const [categoryList, setCategoryData] = useState([]);
  const [specialitiesList, setSpecialitiesData] = useState([]);
  const [facilitiesList, setFacilitiesData] = useState([]);
  const [masterAmenities, setMasterAmenities] = useState([]);
  const [amenities, setAmenities] = useState([]);
  useEffect(()=>{
    if(isEdit){
      let items = user.facility_selected_amenity;
      items.forEach(element => {
        element['label'] = element.amenity
        element['name'] = element.amenity
        element['value'] = element.amenity
      });
      setAmenities(items);
    }
  }, [user.facility_selected_amenity, isEdit]);

  const onLocationChanged = (data) => {
    setLatitude(data.lat)
    setLongitude(data.lng)
    const place = data.place
    const mapObject = getAddressDetails(place.address_components)
    setAddress(place.name)
    setCity(mapObject.city ? mapObject.city : '')
    setState(mapObject.state ? mapObject.state : '')
    setCountry(mapObject.country ? mapObject.country : '')
    setZip(mapObject.zipcode ? mapObject.zipcode : '')
  };
  // const [category, setcategory] = useState(second)

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      //console.log("image_____", event.target.files[0]);
      setImage(URL.createObjectURL(event.target.files[0]));
      setImageData(event.target.files[0]);
    }
  };
  const removePFile = (item) => {
    let certificateUrlList = [...picture];
    let files = [...pictureData];
    setPicture(certificateUrlList.filter((i) => i.id != item.id));
    setpictureData(files.filter((i) => i.id != item.id));
    document.getElementById('profile').value = ""
  }
  const onPictureChange = (event) => {
    if (event.target.files && event.target.files.length) {
      /* let certificateUrlList = []
      for (let i = 0; i < event.target.files.length; i++) {
        const url = URL.createObjectURL(event.target.files[i])
        certificateUrlList.push(url)
      } */
      let certificateUrlList = [...picture];
      let files = [...pictureData];
      if (event.target.files && event.target.files.length > 0) {
        for (let i = 0; i < event.target.files.length; i++) {
          const file = event.target.files[i]
          const url = URL.createObjectURL(file)
          const id = uuidv4()
          certificateUrlList.push({ file_name: url, id: id })
          files.push({
            id: id,
            file: file
          })
        }
        setPicture(certificateUrlList);
        setpictureData(files);
      }

    }
  };
  const removeFile = (item) => {
    let certificateUrlList = [...certificate];
    let files = [...certificateData];
    setCertificate(certificateUrlList.filter((i) => i.id != item.id));
    setcertificateData(files.filter((i) => i.id != item.id));
    document.getElementById('file').value = ""
  }
  const onCertificate = (event) => {
    let certificateUrlList = [...certificate];
    let files = [...certificateData];
    if (event.target.files && event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i]
        const url = URL.createObjectURL(file)
        const id = uuidv4()
        certificateUrlList.push({ file_name: url, id: id })
        files.push({
          id: id,
          file: file
        })
      }
      setCertificate(certificateUrlList);
      setcertificateData(files);
    }
  };
  const handleOnChange = (value, country) => {
    setPhone(value);
    setCountryCode(country.dialCode);
  };
  // const phoneNumberWithoutCode = phone?.replace(countryCode, "");
  const profile = async (e) => {
    e.preventDefault();
    let validate_arr = [
      [fullName, ValidationTypes.Empty, "Please enter first name"],
      [email, ValidationTypes.Email, "Please enter a valid email"],
      [phone, ValidationTypes.Mobile, "Please enter a valid mobile number"],
      [address, ValidationTypes.Empty, "Please enter a valid address"],
      [image, ValidationTypes.Empty, "Please select the image"],
      [about, ValidationTypes.Empty, "Please enter the about detail"],
      [city, ValidationTypes.Empty, "Please enter city"],
    ];
    let validate = await ValidateList(validate_arr);
    if (!validate) {
      return;
    }
    const formData = new FormData();
    formData.append("name", fullName);
    formData.append("image", imageData);
    formData.append("email", email);
    formData.append("user_slug", isEdit ? user.slug : user.user_slug.user_slug);
    formData.append("address", address);
    formData.append("phone_code", countryCode);
    formData.append("country_id", 1);
    formData.append("about", about);
    formData.append("password", password);
    formData.append("phone", phone);
    formData.append("sport_id", 2);
    formData.append("city_id", 2);
    formData.append("gender", gender);
    // formData.append("category_id", category);
    // formData.append("price", price);
    formData.append("country", country);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("zip_code", zip);
    if (latitude) {
      formData.append("latitude", latitude.toString().slice(0, 9));
    }
    if (longitude) {
      formData.append("longitude", longitude.toString().slice(0, 9));
    }
    //formData.append("certificates", certificateData)
    for (const image of certificateData) {
      formData.append("certificates[]", image.file);
    }
    for (const image of pictureData) {
      formData.append("facility_images[]", image.file);
    }
    for (const item of speciality) {
      formData.append("specialities[]", item.name);
    }
    for (const item of facility) {
      formData.append("added_facility[]", item.name);
    }
    for (const item of amenities) {
      formData.append("added_amenity[]", item.name);
    }
    try {
      let resp = await CommonAPi(Endpoints.updateUserDetail, formData);
      if (resp && resp.status === "success") {
        ToastMessage.Success(resp?.message);
        // ToastMessage.Success(isEdit ? resp.message : 'Your profile setup completed');
        if (isEdit) {
          props.loginUserSuccess(resp.data)
        }
        history(isEdit ? '/facility-profile' : '/login');
      }
      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  const getCategoryList = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.getFacilityCategories);
      if (resp && resp.status === "success") {
        setCategoryData(resp.data);
      }
      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  const getSpecialitiesList = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.getSpecialities);
      if (resp && resp.status === "success") {
        let items = resp.data
        items.forEach(element => {
          element['label'] = element.name
          element['value'] = element.id
        });
        if (isEdit) {
          const itemSelected = user.user_speciality.map(item => item.speciality)
          const selectedItem = items.filter(item => itemSelected.includes(item.name))
          setSpeciality(selectedItem)
        }
        setSpecialitiesData(items);
      }
      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  const getFacilitiesList = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.getMasterFacilities);
      if (resp && resp.status === "success") {
        let items = resp.data
        items.forEach(element => {
          element['label'] = element.name
          element['value'] = element.id
        });
        if (isEdit) {
          const itemSelected = user.facility_selected_facility.map(item => item.facility)
          const selectedItem = items.filter(item => itemSelected.includes(item.name))
          setfacility(selectedItem)
        }
        setFacilitiesData(items);
      }
      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    } catch (e) {
      console.log("registerErr__", JSON.stringify(e.response, null, 4));

      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  const getMasterAmenities = async (e) => {
    try {
      let resp = await FetchApi(Endpoints.GetMasterAmenities);
      if (resp && resp.status === "success") {
        let items = resp.data
        items.forEach(element => {
          element['label'] = element.name
          element['value'] = element.id
        });

        setMasterAmenities(items);
      }
      if (resp && resp.message && resp.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    } catch (e) {
      if (e && e.response && e.response.message && e.response.message.email) {
        Swal.fire({
          text: "This email is already used",
          icon: "error",
        });
      }
    }
  };
  useEffect(() => {
    // getCategoryList();
    getSpecialitiesList();
    getFacilitiesList();
    getMasterAmenities();
    if (userSlug.state === null) {
      history('/login')
    }
  }, []);
  return (
    <div className="main py-5">
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-lg-6 col-md-8">
            <div className="position-relative profile-thumb overflow-hidden rounded-circle mb-4">
              <input
                onChange={(e) => {
                  onImageChange(e);
                }}
                accept=".png, .jpg, .jpeg"
                type="file"
                id="profile-field"
                className="d-none"
              />
              <img
                src={image ? image : "assets/img/edit-profile.png"}
                className="profile-pic rounded-circle "
                alt=""
              />
              <label
                htmlFor="profile-field"
                className="profile-upload d-flex align-items-center justify-content-center start-0 bottom-0 position-absolute"
              >
                <img src="assets/img/icon/cover-picture.svg" alt="" />
              </label>
            </div>

            <form
              onSubmit={profile}
              action=""
              className="site-form pt-2 coach-profile-form"
            >
              <div className="form-field mb-3">
                <label htmlFor="" className="pb-1">
                  Facility Name
                </label>
                <input
                  onChange={(e) => setFullName(e.target.value)}
                  type="text"
                  placeholder="Figure Skating - Canada"
                  value={fullName}
                />
              </div>
              <div className="form-field mb-3">
                <label htmlFor="" className="pb-1">
                  Email Address
                </label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  className="bg-lightgreen border-0"
                  placeholder="Enter email address"
                  value={email}
                />
              </div>
              <div className=" mb-3">
                <label htmlFor="" className="pb-1">
                  Contact Number
                </label>
                <div className="d-flex custom-select">
                  <PhoneInput
                    containerClass=""
                    country={"us"}
                    value={phone}
                    onChange={handleOnChange}
                    inputClass="form-control"
                  />
                </div>
              </div>

              {/* <div className="form-field mb-3">
                <label htmlFor="" className="pb-1">
                  Facility Category
                </label>
                <select
                  onChange={(e) => setCategory(e.target.value)}
                  name=""
                  id="" value={category}
                ><option value="">
                    Select Category
                  </option>
                  {categoryList.map((item, i) => {
                    return (<option value={item.id} key={i}>
                      {item.name}
                    </option>)
                  })}
                </select>
              </div> */}

              <div className="form-field mb-3">
                <label htmlFor="" className="pb-1">
                  About
                </label>
                <textarea
                  onChange={(e) => setAbout(e.target.value)}
                  name="the-textarea"
                  cols="10"
                  rows="4"
                  placeholder="Write about yourself.." value={about}
                ></textarea>
              </div>

              <div className="form-field mb-3">
                <label htmlFor="" className="pb-2 d-block">
                  Add Pictures
                </label>
                <div className="d-flex gap-2">
                  <div>
                    <input
                      onChange={(e) => {
                        onPictureChange(e);
                      }}
                      accept="image/*"
                      multiple
                      type="file"
                      id="profile"
                      className="d-none"
                    />
                    <label
                      htmlFor="profile"
                      className="custom-pic text-green p-3 fs-13 light text-center"
                    >
                      {/* <img
                        src={pictureData}
                        className="d-block mx-auto mb-2"
                        alt=""
                      /> */}
                      Upload Pictures
                    </label>
                  </div>
                  {picture.map((item, i) => {
                    return (
                      <div className="position-relative add-pic d-flex align-items-center justify-content-center" key={i}>
                        <img src={item.file_name ? item.file_name : item.image} alt="" />
                        <a onClick={(e) => removePFile(item)} className="position-absolute top-0 end-0 p-1">
                          <i className="fa fa-xmark-circle"></i>
                        </a>
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="form-field mb-3">
                <label htmlFor="" className="pb-1">
                Add Amenities
                </label>
                <Select isMulti={true} value={amenities} onChange={(value) => {
                  setAmenities(value)
                }} options={masterAmenities} />
              </div>
{/* 
              <div className="form-field mb-3">
                <label htmlFor="" className="pb-1">
                  Add Facilities
                </label>
                <Select isMulti={true} value={facility} onChange={(value) => {
                  setfacility(value)
                }} options={facilitiesList} />
              </div> */}

              <div className="form-field mb-3">
                <label htmlFor="" className="pb-1 d-block">
                  Certificates
                </label>
                <input
                  onChange={(e) => onCertificate(e)}
                  name="myImage"
                  type="file"
                  className="d-none"
                  id="file"
                  accept="image/*"
                  multiple
                />
                <label
                  htmlFor="file"
                  className="upload-file text-green bg-lightgreen d-inline-block fs-13 light text-center px-4"
                >
                  Upload Certificates
                </label>
                <div className="d-flex gap-2 mt-2">
                  {certificate.map((item, i) => {
                    return (
                      <div className="position-relative add-pic d-flex align-items-center justify-content-center" key={i}>
                        <img src={item.file_name ? item.file_name : item.image} alt="" />
                        <a onClick={(e) => removeFile(item)} className="position-absolute top-0 end-0 p-1">
                          <i className="fa fa-xmark-circle"></i>
                        </a>
                      </div>
                    )
                  })}
                </div>
              </div>
              {/* <div className="form-field mb-3">
                <label htmlFor="" className="pb-1">
                  Price
                </label>
                <input
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                  type="number"
                  placeholder="Price"
                />
              </div> */}
              <div className="form-field mb-3">
                <label htmlFor="" className="pb-1">
                  Address
                </label>
                {/* <input
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                  placeholder="Address" value={address}
                /> */}
                <div className="map-filed">
                  <LocationAutocomplete onLocationChanged={onLocationChanged} address={address}></LocationAutocomplete>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-field mb-3">
                    <label htmlFor="" className="pb-1">
                      City
                    </label>
                    <input
                      onChange={(e) => setCity(e.target.value)}
                      type="text"
                      placeholder="City" value={city}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-field mb-3">
                    <label htmlFor="" className="pb-1">
                      State
                    </label>
                    <input
                      onChange={(e) => setState(e.target.value)}
                      type="text"
                      placeholder="State" value={state}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-field mb-3">
                    <label htmlFor="" className="pb-1">
                      Country
                    </label>
                    <input
                      onChange={(e) => setCountry(e.target.value)}
                      type="text"
                      placeholder="State" value={country}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-field mb-3">
                    <label htmlFor="" className="pb-1">
                      Zip Code
                    </label>
                    <input
                      onChange={(e) => setZip(e.target.value)}
                      type="text" value={zip}
                      placeholder="Enter zip code"
                    />
                  </div>
                </div>
              </div>
              <div className="btn-group d-flex gap-2 justify-content-center">
                <button className="gray-butn butn  rounded-pill border-0" onClick={(e) => {
                  e.preventDefault()
                  history(isEdit ? '/facility-profile' : '/signup');
                }}>
                  Back
                </button>
                <button type="submit" className="butn rounded-pill border-0">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    isLoading: state.Auth.isLoading
  }
}

export default connect(mapStateToProps, { loginUserSuccess })(FacilityProfileComplete);