import React, { useEffect, useState } from 'react'
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import moment from "moment";
import { CommonAPi } from "../../API/CommonApi";
import {  useParams, useNavigate } from "react-router-dom";

export default function Edit() {
    const [adaTitle, setAdaTitle] = useState("");
    const [adaFor, setAdaFor] = useState("");
    const [adaDescription, setAdaDescription] = useState("");
    const [adaType, setAdaType] = useState("");
    const [adaStartDate, setAdaStartDate] = useState("");
    const [adaEndDate, setAdaEndDate] = useState("");
    const [image, setImage] = useState("");
    const [imagePreview, setImagePreview] = useState("");
    const [Id, setId] = useState("");
    const params = useParams();
    const navigate = useNavigate()

    const [adaTitleError, setAdaTitleError] = useState("");
    const [adaForError, setAdaForError] = useState("");
    const [adaTypeError, setAdaTypeError] = useState("");
    const [adaDescriptionError, setAdaDescriptionError] = useState("");
    const [adaStartDateError, setAdaStartDateError] = useState("");
    const [adaEndDateError, setAdaEndDateError] = useState("");

    useEffect(()=>{
        GetAdsData();
    }, []);

    const GetAdsData = async() =>{
        let data ={
            id:params?.id
        }
        try {
            let resp = await CommonAPi(Endpoints.GetSingalAdvertisements, data);
            if (resp && resp.status === "success") {
                setAdaEndDate(resp?.data?.add_expire_date);
                setAdaStartDate(resp?.data?.add_start_date);
                setAdaType(resp?.data?.add_type);
                setAdaDescription(resp?.data?.description);
                setAdaFor(resp?.data?.add_for);
                setAdaTitle(resp?.data?.add_title);
                setId(resp?.data?.id);
                setImagePreview(resp?.data?.image);
               
            }
        } catch (e) {
            if (e && e.response && e.response.message && e.response.message.email) {
                Swal.fire({
                    text: "This email is already used",
                    icon: "error",
                });
            }
        }
    
    };

    const handleImage = (e) => {
        setImage(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!adaTitle) {
            setAdaTitleError("Advertisement title is required");
        } else {
            setAdaTitleError("");
        }
        if (!adaFor) {
            setAdaForError("Advertisement for is required");
        } else {
            setAdaForError("");
        }
        if (!adaType) {
            setAdaTypeError("Advertisement type is required");
        } else {
            setAdaTypeError("");
        }
        // if (!adaDescription) {
        //     setAdaDescriptionError("Advertisement description is required");
        // } else {
        //     setAdaDescriptionError("");
        // }
        if (!adaStartDate) {
            setAdaStartDateError("Advertisement start date is required");
        } else {
            setAdaStartDateError("");
        }
        if (!adaEndDate) {
            setAdaEndDateError("Advertisement start end is required");
        } else {
            setAdaEndDateError("");
        }


        if (adaTitle && adaFor && adaType && adaStartDate && adaEndDate) {
            const formData = new FormData();
            formData.append("add_title", adaTitle);
            formData.append("id", Id);
            formData.append("add_for", adaFor);
            formData.append("add_type", adaType);
            formData.append("description", adaDescription);
            formData.append("add_start_date", moment(adaStartDate).format("YYYY/MM/DD"));
            formData.append("add_expire_date", moment(adaEndDate).format("YYYY/MM/DD"));
            if (image) {
                formData.append("image", image);
            }
            try {
                let resp = await CommonAPi(Endpoints.CreateAdvertisement, formData);
                if (resp && resp.status === "success") {
                    setImage("");
                    setAdaEndDate("");
                    setAdaStartDate("");
                    setAdaType("");
                    setAdaFor("");
                    setAdaTitle("");
                    setAdaDescription('');
                    Swal.fire({
                        text: "Advertisement Updated Successfully",
                        icon: "success",
                    });
                    navigate('/manage-advertisement')
                }
            } catch (e) {
                if (e && e.response && e.response.message && e.response.message.email) {
                    Swal.fire({
                        text: "This email is already used",
                        icon: "error",
                    });
                }
            }
        }
    };

    const handleCancel = () =>{
        Swal.fire({
            text: "You have unsaved changes, do you still want to exit?",
            icon: "question",
            customClass: {
              confirmButton: "btn px-3 mx-2",
              cancelButton: "btn btn-br-blue px-3",
            },
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Yes, discard it!",
          }).then((result) => {
            if (result.isConfirmed) {
                setImage("");
                setAdaEndDate("");
                setAdaStartDate("");
                setAdaType("");
                setAdaFor("");
                setAdaTitle("");
                navigate('/manage-advertisement')
            }
          });
    };

    return (
        <div className="wrapper">
            <div className="main py-5">
                <div className="container">
                    <div className="row justify-content-center ">
                        <div className="col-lg-6 col-md-8">
                            <h1 className="text-darkgray semibold pb-2 text-center fs-24">
                                Edit Advertisement
                            </h1>

                            <form onSubmit={handleSubmit} className="site-form pt-2">
                                <div className="form-field mb-3">
                                    <label htmlFor="" className="pb-1">
                                        Advertisement Title
                                    </label>
                                    <input
                                        value={adaTitle}
                                        onChange={(e) => {
                                            setAdaTitle(e.target.value)
                                        }}

                                        type="text"
                                        placeholder="Enter Advertisement Title"
                                    />
                                    {adaTitleError ? 
                                    <p className="text-danger">{adaTitleError}</p>
                                    : ""}
                                </div>
                                <div className="form-field mb-3">
                                    <label  className="pb-1">
                                        Advertisement For
                                    </label>
                                    <select  
                                        onChange={(e) => {
                                            setAdaFor(e.target.value)
                                        }}
                                    >
                                        <option>Select Advertisement For</option>
                                        <option value={"athelete"} selected={adaFor == "athelete" ? true : false}>Athlete</option>
                                        <option value={"coach"} selected={adaFor == "coach" ? true : false}>Coach</option>
                                        <option value={"everyone"} selected={adaFor == "everyone" ? true : false}>Everyone</option>
                                    </select>

                        
                                     {adaForError ? 
                                    <p className="text-danger">{adaForError}</p>
                                    : ""}
                                </div>
                                <div className="form-field mb-3">
                                    <label  className="pb-1">
                                        Advertisement Type
                                    </label>
                                    <select
                                    onChange={(e) => {
                                        setAdaType(e.target.value)
                                    }}
                                    >
                                        <option>Select Advertisement Type</option>
                                        <option value={"promotion"} selected={adaType == "promotion" ? true : false}>Promotion</option>
                                        <option value={"lead"} selected={adaType == "lead" ? true : false}>Lead</option>
                                    </select>
                                    
                                     {adaTypeError ? 
                                    <p className="text-danger">{adaTypeError}</p>
                                    : ""}
                                </div>

                                {/* <div className="form-field mb-3">
                                    <label  className="pb-1">
                                        Advertisement Description
                                    </label>
                                    <textarea
                                        value={adaDescription}
                                        onChange={(e) => {
                                            setAdaDescription(e.target.value)
                                        }}

                                        placeholder="Enter Advertisement Description"
                                    />
                                    {adaDescriptionError ? 
                                    <p className="text-danger">{adaDescriptionError}</p>
                                    : ""}
                                </div> */}

                                <div className="form-field mb-3">
                                    <label htmlFor="" className="pb-1">
                                        Advertisement Start Date
                                    </label>
                                    <input
                                        type="date"
                                        value={adaStartDate}
                                        onChange={(e) => {
                                            setAdaStartDate(e.target.value)
                                        }}
                                    />
                                     {adaStartDateError ? 
                                    <p className="text-danger">{adaStartDateError}</p>
                                    : ""}
                                </div>
                                <div className="form-field mb-3">
                                    <label htmlFor="" className="pb-1">
                                        Advertisement End Date
                                    </label>
                                    <input
                                        type="date"
                                        value={adaEndDate}
                                        onChange={(e) => {
                                            setAdaEndDate(e.target.value)
                                        }}
                                    />
                                     {adaEndDateError ? 
                                    <p className="text-danger">{adaEndDateError}</p>
                                    : ""}
                                </div>
                                <div className="form-field mb-3">
                                    <label htmlFor="" className="pb-1">
                                        Advertisement Image
                                    </label>
                                    <input
                                        type="file"

                                        onChange={(e) => {
                                            handleImage(e)
                                        }}
                                    />
                                    <div className="mt-2">
                                        <img src={imagePreview} alt='' width={150} />
                                    </div>
                                 
                                    
                                </div>

                                <div className="d-flex gap-2">
                                <a
                                href='#'
                                   onClick={()=>{
                                    handleCancel()
                                   }}
                                    className="gray-butn butn w-50 rounded-pill border-0"
                                >
                                    Cancel
                                </a>
                                <button
                                    type="submit"
                                    className="butn w-50 rounded-pill border-0"
                                >
                                    Submit
                                </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
